<template>
  <div>
    <nav
      class="flex bg-opacity-0 border-opacity-25 p-1 border-b border-zinc-600"
    >
      <div class="flex-none">
        <span
          v-if="isConnected"
          class="text-yellow-50 inline-block text-sm px-2 py-2"
        >
          <i class="fad fa-signal text-green-300"></i> Connected ({{
            onlineUsers
          }})
        </span>
        <span v-else class="text-yellow-50 inline-block text-sm px-2 py-2">
          <i class="fad fa-signal-alt-slash text-gray-500"></i>
          Disconnect
        </span>
      </div>
      <div class="flex-grow text-right">
        <div class="inline-block">
          <div
            class="text-sm px-2 py-1 sm:px-4 sm:py-2 border-none rounded text-white bg-gray-900 cursor-pointer mr-2"
          >
            <span v-if="checkChain">
              <img
                class="h-5 inline"
                :src="require(`@/assets/chain/${CHAIN.toLowerCase()}.png`)"
              />
              {{ CHAIN }}
            </span>
            <span v-else v-on:click="addNetwork()">
              <i class="fad fa-engine-warning mr-1" />
              Switch Network
            </span>
          </div>
        </div>

        <div
          v-if="loading"
          href="#"
          class="inline-block text-sm px-2 py-1 sm:px-4 sm:py-2 border rounded text-gray-400 border-gray-400 cursor-wait"
        >
          <i class="fad fa-spinner-third fa-spin mr-1"></i>Loading
        </div>
        <div v-else-if="token" class="inline-block">
          <router-link
            to="/funds"
            class="inline-block mt-1 text-sm px-2 py-1 sm:px-4 sm:py-2 border rounded text-yellow-50 border-none mr-2 bg-gradient-to-r from-blue-600 to-green-600"
          >
            <i class="fad fa-wallet mr-1"></i
            >{{ getUser.address.slice(0, 6) }}...{{ getUser.address.slice(-4) }}
          </router-link>
          <div
            class="inline-block text-sm py-1 sm:px-2 sm:py-2 text-yellow-50 cursor-pointer"
            v-on:click="signout()"
          >
            <i class="fad fa-sign-out-alt"></i>
          </div>
        </div>
        <div v-else class="inline-block">
          <div
            class="inline-block mt-1 p-px bg-gradient-to-r from-blue-700 to-red-500 rounded"
          >
            <div
              class="inline-block text-sm px-2 py-1 sm:px-4 sm:py-2 border-none rounded text-yellow-50 bg-gray-900 cursor-pointer"
              v-on:click="login()"
              v-if="!token"
            >
              <img
                src="@/assets/svg/metamask.svg"
                class="inline-block mr-1 h-5"
              />
              Connect wallet
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import nativeToast from "native-toast";
import Web3 from "web3";
import Web3Token from "web3-token";
import { TESTNET, CHAIN, NETWORK_VERSION } from "../config";

import { mapGetters, mapActions } from "vuex";

import user from "@/api/user";

export default {
  data() {
    return {
      CHAIN: CHAIN,
      loading: false,
      token: JSON.parse(localStorage.getItem("Token")),
      networkVersion: JSON.parse(localStorage.getItem("networkVersion")),
      checkChain: false,
    };
  },
  computed: mapGetters(["getToken", "getUser", "isConnected", "onlineUsers"]),
  methods: {
    ...mapActions([
      "setToken",
      "setUser",
      "fetchBalance",
      "setBalance",
      "logout",
    ]),
    async login() {
      try {
        this.loading = true;
        const web3 = new Web3(ethereum);

        await window.ethereum.request({ method: "eth_requestAccounts" });
        // getting address from which we will sign message
        this.address = (await web3.eth.getAccounts())[0];

        // generating a token with 1 day of expiration time
        this.token = await Web3Token.sign(
          (msg) => web3.eth.personal.sign(msg, this.address),
          "1d"
        );

        await user
          .login({
            token: this.token,
            ref: JSON.parse(localStorage.getItem("Ref")),
          })
          .then((response) => {
            this.loading = false;

            this.setToken(this.token);
            this.setUser(response.data.user);

            this.fetchBalance();
            this.sockets.subscribe(
              "user.balance." + this.getUser._id,
              (data) => {
                this.setBalance(data);
              }
            );

            nativeToast({
              message: "Wallet Connected",
              position: "top",
              timeout: 3000,
              type: "success",
            });
          })
          .catch((error) => {
            this.loading = false;
          });
      } catch (error) {
        this.loading = false;
        nativeToast({
          message: error.message,
          position: "top",
          timeout: 3000,
          type: "error",
        });
      }
    },
    signout() {
      this.sockets.unsubscribe("user.balance." + this.getUser._id);
      this.token = null;
      this.logout();
    },
    async addNetwork() {
      try {
        if (TESTNET) {
          await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: "0x61" }],
          });
        } else {
          //Mainnet
          await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: "0x38" }],
          });
        }
      } catch (switchError) {
        // This error code indicates that the chain has not been added to MetaMask.
        if (switchError.code === 4902) {
          try {
            if (TESTNET) {
              await window.ethereum.request({
                method: "wallet_addEthereumChain",
                params: [
                  {
                    chainId: "0x61",
                    chainName: "Binance Smart Chain Testnet",
                    rpcUrls: ["https://data-seed-prebsc-2-s3.binance.org:8545"],
                    nativeCurrency: {
                      name: "BINANCE COIN",
                      symbol: "TBNB",
                      decimals: 18,
                    },
                    blockExplorerUrls: ["https://testnet.bscscan.com/"],
                  },
                ],
              });
            } else {
              //Mainnet
              await window.ethereum.request({
                method: "wallet_addEthereumChain",
                params: [
                  {
                    chainId: "0x38",
                    chainName: "Binance Smart Chain Mainnet",
                    rpcUrls: ["https://bsc-dataseed.binance.org/"],
                    nativeCurrency: {
                      name: "BINANCE COIN",
                      symbol: "BNB",
                      decimals: 18,
                    },
                    blockExplorerUrls: ["https://bscscan.com/"],
                  },
                ],
              });
            }
          } catch (addError) {
            nativeToast({
              message: addError,
              position: "top",
              timeout: 3000,
              type: "error",
            });
          }
        }
        nativeToast({
          message: switchError.message,
          position: "top",
          timeout: 3000,
          type: "error",
        });
      }
      window.location.reload();
    },
  },
  created() {
    if (window.ethereum) {
      window.ethereum.on("chainChanged", (_chainId) => {
        window.location.reload();
      });
      if (window.ethereum.networkVersion == NETWORK_VERSION) {
        this.checkChain = true;
      }
      if (this.getUser) {
        window.ethereum.on("accountsChanged", (accounts) => {
          this.sockets.unsubscribe("user.balance." + this.getUser._id);
          this.logout();
          this.token = null;
          window.location.reload();
        });
      }
    }
  },
};
</script>
